 
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import L from 'leaflet';
import { UseCompany } from '../Components/useCompany';

// Fix default marker icon issues
delete L.Icon.Default.prototype._getIconUrl;

L.Icon.Default.mergeOptions({
  iconRetinaUrl: 'https://unpkg.com/leaflet@1.7.1/dist/images/marker-icon-2x.png',
  iconUrl: 'https://unpkg.com/leaflet@1.7.1/dist/images/marker-icon.png',
  shadowUrl: 'https://unpkg.com/leaflet@1.7.1/dist/images/marker-shadow.png',
});

const MapComponent = () => {
    const  companyInfos =   UseCompany();   

    // Check if companyInfos, MapN, and MapE exist and are valid numbers
    if (!companyInfos || isNaN(Number(companyInfos.MapN)) || isNaN(Number(companyInfos.MapE))) {
      // Return null, loading state, or handle the error appropriately
      return <div>Loading...</div>; 
    }
    
    // Convert the values to numbers
    const MapN = Number(companyInfos.MapN);
    const MapE = Number(companyInfos.MapE);
    
    // Now you can safely define the position
    const position = [MapN, MapE];
  
  // const position = [MapN, MapE]; // Default location (Jouzour lebanon)33.8878881545174, 35.51920766679271
  // const position = [33.8878881545174, 35.51920766679271]; // Default location (Jouzour lebanon)33.8878881545174, 35.51920766679271

  return (
    <MapContainer center={position} zoom={13} style={{ height: '400px', width: '100%' }}>
      <TileLayer
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
      />
      <Marker position={position}>
        <Popup>
          A pretty CSS3 popup. <br /> Easily customizable.
        </Popup>
      </Marker>
    </MapContainer>
  );
};

export default MapComponent;

