import { useEffect, useState } from "react"; 
import { GetContentSlider } from "../data/ContentSliderController";

export const UseContentSlider = () => { 
  const [ContentSlider, setContentSlider] = useState([]);
  useEffect(() => {
    const func = async () => {
      try {
        const GetData = await GetContentSlider();
        setContentSlider(GetData);
      } catch (error) {
        console.log(error);
      }
    };

    func();
  }, []);

  return ContentSlider;
};
