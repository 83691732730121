import { useEffect, useState } from "react";
import { GetCompanyInfos } from "../data/CompanyController"; 

export const UseCompany = () => {
  const [CompanyInfos, setCompanyInfos] = useState([]);
  useEffect(() => {
    const func = async () => {
      try {
        const GetData = await GetCompanyInfos();
        setCompanyInfos(GetData);
      } catch (error) {
        console.log(error);
      }
    };

    func();
  }, []);

  return CompanyInfos;
};
