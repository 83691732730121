

import "./content-slider3.css"

const Contentslider3 = () => {
    return (
      <div className="content-slider-section3">
        <div className="content-slider3">
          <div className="content-slider-content3">
            <h1>System</h1>
          </div>
          <div className="content-slider-content3">
            <h1>Design</h1>
          </div>
          <div className="content-slider-content3">
            <h1>Solor</h1>
          </div>
          <div className="content-slider-content3">
            <h1>Power</h1>
          </div>
          <div className="content-slider-content3">
            <h1>Design</h1>
          </div>
          <div className="content-slider-content3">
            <h1>Solor</h1>
          </div>
          <div className="content-slider-content3">
            <h1>Energy</h1>
          </div>
        </div>
        <div className="content-slider3">
          <div className="content-slider-content3">
            <h1>System</h1>
          </div>
          <div className="content-slider-content3">
            <h1>Design</h1>
          </div>
          <div className="content-slider-content3">
            <h1>Solor</h1>
          </div>
          <div className="content-slider-content3">
            <h1>Power</h1>
          </div>
          <div className="content-slider-content3">
            <h1>Design</h1>
          </div>
          <div className="content-slider-content3">
            <h1>Solor</h1>
          </div>
          <div className="content-slider-content3">
            <h1>Energy</h1>
          </div>
        </div>
      </div>
    );
};

export default Contentslider3;