export const GetContentSlider= async () => {  
    try {
      const response = await fetch("/api/", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "Connection": "keep-alive",
        },
        body: JSON.stringify({ action: "GetContentSlider" }),
        // credentials: "include",
  
      });
      if (!response.ok) { 
        throw new Error(`HTTP error! status: ${response.status} - ${response.statusText}`);
      }
      const data = await response.json();   
      return data.data;    
    } catch (error) {
      console.error("Error fetching data:", error);
      console.log(`Error message: ${error.message}`);
    }
  }; 