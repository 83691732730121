import { useEffect, useState } from "react"; 
import { GetHeaderSlider } from "../data/HeaderSliderController";

export const UseHeaderSlider = () => { 
  const [HeaderSlider, setHeaderSlider] = useState([]);
  useEffect(() => {
    const func = async () => {
      try {
        const GetData = await GetHeaderSlider();
        setHeaderSlider(GetData);
    } catch (error) {
        console.log(error);
      }
    };

    func();
  }, []);

  return HeaderSlider;
};
